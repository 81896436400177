<template>
  <NavBar/>
  <router-view/>
  <SiteFooter/>
</template>

<script>
import NavBar from './components/NavBar.vue';
import SiteFooter from './components/SiteFooter.vue';
export default {
  name: 'App',
  components: {
    NavBar,
    SiteFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
