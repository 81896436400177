
<template>

    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid" id = "nav">
        <a class="navbar-brand" href="#"><img src="../assets/unnamed.jpg" width="100" height="50" alt="Logo"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item">
              <!-- <a class="nav-link active" aria-current="page" href="#">Home</a> -->
              <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
            </li>
             <!-- <li class="nav-item">
              <router-link to="/" class="active" aria-current="page">Home</router-link>
             </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/about">About</a>
              <!-- <router-link to="">About</router-link> -->
            </li>
          </ul>    
        </div>
      </div>
    
    </nav>
    </template>
    
    <script>
    // import { RouterLink } from 'vue-router';
    
    export default {
      name: 'NavBar',
      components: {
        
      }
    }
    </script>
    
    
    <style>
    
    .container-fluid a img{
        border-radius: 30%;
    }
    
    ul {
      list-style: none;
    }
    
    .example-2 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .example-2 .icon-content {
      margin: 0 10px;
      position: relative;
    }
    .example-2 .icon-content .tooltip {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      padding: 6px 10px;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      font-size: 14px;
      transition: all 0.3s ease;
    }
    .example-2 .icon-content:hover .tooltip {
      opacity: 1;
      visibility: visible;
      top: 70px;
    }
    .example-2 .icon-content a {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: #4d4d4d;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
    }
    .example-2 .icon-content a:hover {
      box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
    }
    .example-2 .icon-content a svg {
      position: relative;
      z-index: 1;
      width: 30px;
      height: 30px;
    }
    .example-2 .icon-content a:hover {
      color: white;
    }
    .example-2 .icon-content a .filled {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #000;
      transition: all 0.3s ease-in-out;
    }
    .example-2 .icon-content a:hover .filled {
      height: 100%;
    }
    .example-2 .icon-content a[data-social="spotify"] .filled,
    .example-2 .icon-content a[data-social="spotify"] ~ .tooltip {
      background-color: #1db954;
    }
    .example-2 .icon-content a[data-social="pinterest"] .filled,
    .example-2 .icon-content a[data-social="pinterest"] ~ .tooltip {
      background-color: #bd081c;
    }
    .example-2 .icon-content a[data-social="dribbble"] .filled,
    .example-2 .icon-content a[data-social="dribbble"] ~ .tooltip {
      background-color: #ea4c89;
    }
    .example-2 .icon-content a[data-social="telegram"] .filled,
    .example-2 .icon-content a[data-social="telegram"] ~ .tooltip {
      background-color: #0088cc;
    }
    
    @media (min-width:992px) {
          .collapse {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
    }
    
    @media (max-width:330px) {
          .example-2{
            display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          }
    
          .example-2 .icon-content .tooltip{
          left: 90%;
          transform: translateY(-60%);
          }
        }
    
    </style>