<template>
    <div class="container-video">
      <video src="../assets/3205624-hd_1920_1080_25fps.mp4" muted autoplay loop></video>
      <div class="wel-div">
        <p>Welcome to <br>Start-Up Ignition Team</p>
      </div>
      <div class="class-one">
        <p>Get Started Right now</p>
        <ul>
          <li><img src="../assets/search.svg" width="20" alt=""><span>Find a Meeting</span></li>
          <li><img src="../assets/invite.svg" width="20" alt=""><span>Get an Invite</span></li>
          <li><img src="../assets/tick.svg" width="20" alt="">Attend the Meeting</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BanNer'
  }
  </script>
  
  <style>
  .container-video {
    position: relative;
    height: 70vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.352);
  }
  
  .wel-div {
    position: relative;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }
  
  .container-video video {
    position: absolute;
    top: 0;
    left: 0;
    min-height: calc(100% - 100px);
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
  }
  
  .class-one {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: #c11d38;
    background-image: linear-gradient(315deg, #c11d38 0%, #ffc857 74%);
    padding: 1rem;
  }
  
  .class-one::after {
    content: "";
    width: 30vw;
    height: 1px;
    border-top: 2px dashed #fff;
    position: absolute;
    left: 35%;
    z-index: 1;
  }
  
  .class-one ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  
  .class-one ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .class-one ul li img {
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 2px;
    background-color: #fff;
    z-index: 10;
  }
  
  @media (max-width:670px) {
    .class-one ul li {
      font-size: 1rem;
    }
  
    .class-one::after {
      width: 45vw;
      top: 75px;
      left: 30%;
    }
  }
  
  @media (max-width:490px) {
    .class-one ul li {
      font-size: 0.6rem;
    }
  
    .class-one::after {
      width: 45vw;
      top: 75px;
      left: 30%;
    }
  }
  </style>
  