<template>
    <div>
        <About/>
    </div>
</template>
  
  <script>
  import About from '@/components/About.vue';
  export default {
    name: 'AboutPage',
    components: {
      About,
    }
  }
  </script>
  
  <style></style>