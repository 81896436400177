<template>
    <div class="body">
            <div class="head">
                <h1>Our 4 Focus Pillars</h1>
            </div>
            <div class="about-cont">
                <div class="pillars" data-tooltip="In the digital age, harnessing supreme knowledge capital through digital mediums is crucial. Providing valuable services and engaging in problem-solving gain experience and foster innovation. Emphasizing the academia-industry interface enhances the practical application of this knowledge.">
                    <img src="../assets/Pillar.svg" class="pillar-1 pillar" width="200"><span>Collective Wisdom</span>
                </div>
                <div class="pillars" data-tooltip="Our evangelism strategy leverages unique individual styles to enhance our brand image. Each team member contributes distinctively, fostering a diverse and dynamic approach. Explore our repository of case studies to see the impact and success stories.">
                    <img src="../assets/Pillar.svg" class="pillar-2 pillar" width="200"><span>Best Practices & Case Studies</span>
                </div>
                <div class="pillars" data-tooltip="Utilize our extensive network to align diverse talent needs and enhance productivity. By leveraging the best expertise from various countries, we aim to uplift and develop global talent. Explore how our approach fosters growth and innovation across borders.">
                    <img src="../assets/Pillar.svg" class="pillar-3 pillar" width="200"><span>Networking</span>
                </div>
                <div class="pillars" data-tooltip="Work in smaller groups for each target and define a clear road-map. Focus on smaller, manageable targets and monitor progress closely, utilizing resources within each group. Emulate and build on smaller success stories to achieve larger goals.">
                    <img src="../assets/Pillar.svg" class="pillar-4 pillar" width="200"><span>Target Based Working</span>
                </div>
            </div>

            <div class="body-1">
                <h1>VISION</h1>
                <p>Be a one-stop solution provider to startup aspirants from Tier-I/II/III towns</p>
                <h1>OBJECTIVE</h1>
                <ul>
                    <li><p>Networking is our <Strong>Primary</Strong> Objective</p></li>
                    <li><p>Sharing of Best Practices/ Success Stories</p></li>
                    <li><p>Sharing of challenges and seeking help</p></li>
                    <li><p>Information exchange related to startup/ investment/ opportunity/ business growth</p></li>
                </ul>
            </div>
        </div>
</template>
<script>
export default {
    name:'AboutPage',
    components:{

    }
}
</script>

<style>
    .body {
                min-height: 70vh;
            }

            .about-cont {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                justify-content: space-evenly;
                align-items: center;
            }

            .pillars {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                position: relative;
            }

            .pillars span {
                display: block;
                background: linear-gradient(to right,#ff0000, #fdcf58);
                border-radius: 10px;   
                padding: .3rem;
                margin: .3rem; 
                min-width: 10rem;
                text-align: center;
                font-weight: bold;
                color: white;
            }

            .head{
                text-align: center;
                padding: 2rem;
                margin: 3rem;
            }
            .head h1{
                font-weight: bold;
                font-style: italic;
                font-family: sans-serif;
                text-align: center;
            }

            .body-1{
                margin: 2rem;
                text-align: center;
                background-color: #e5d11d;
                padding: 1rem;
                margin: 2rem 5rem 0 5rem;
                border-radius: 10px;
            }

            .pillars::after{
                --scale:0;
                position: absolute;
                content: attr(data-tooltip);
                background: linear-gradient(to right,#ff0000, #fdcf58);
                top:-8rem;
                left: 50%;
                width: max-content;
                max-width: 90%;
                transform: translateX(-45%) translateY(-30%) scale(var(--scale));
                margin: .1rem;
                padding: .5rem;
                color: white;
                transition: 200ms transform;
                transform-origin: bottom center;
                border-radius: .5rem;
            }

            .pillars:hover::after{
                --scale:1;
            }

            @media (max-width:820px) {
                .about-cont {
                display: grid;
                grid-template-columns: 1fr 1fr ;
            }
        }

            @media (max-width:420px) {
                .about-cont {
                display: grid;
                grid-template-columns: 1fr;
            }

            .head{
                padding: 0;
            }
            .pillars {
                margin: 1rem;
            }
        }
</style>