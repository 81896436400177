<template>
   <div>
    <Banner/>
   </div>
</template>
  
  <script>
  import Banner from '@/components/Banner.vue';
  export default {
    name: 'HomePage',
    components: {
      Banner
    }
  }
  </script>
  
  <style></style>